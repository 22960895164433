import { defineStore } from "pinia";
import { useAuthStore } from "./auth.store";
import { useHandleErrorStatus } from "@/composable/useHandleErrorStatus";
import { http } from "@/composable/httpService";

export const useLmsSettingsStore = defineStore("LmsSettingsStore", {
  state: () => ({
    settings: [],
    loadingSettings: false,
  }),
  getters: {
    authUser() {
      return useAuthStore().user;
    },
    hasEnrollment() {
      if (this.authUser?.dealer?.lms_settings?.length) {
        return this.authUser.dealer.lms_settings.some((setting) => {
          return setting.system_name === "course_enrollment";
        });
      }

      return false;
    },
    hasRequireArp() {
      if (this.authUser?.dealer?.lms_settings?.length) {
        return this.authUser.dealer.lms_settings.some((setting) => {
          return setting.system_name === "require_arp";
        });
      }

      return false;
    },
    hasRequireAssignStartAndEndDate() {
      if (
        this.authUser &&
        this.authUser.dealer &&
        this.authUser.dealer.lms_settings &&
        this.authUser.dealer.lms_settings.length
      ) {
        return this.authUser.dealer.lms_settings.some((setting) => {
          return setting.system_name === "require_assign_start_and_end_date";
        });
      }

      return false;
    },
    isRestrictQuiz() {
      if (this.authUser?.dealer?.lms_settings?.length) {
        return this.authUser.dealer.lms_settings.some((setting) => {
          return setting.system_name === "restrict_quiz";
        });
      }

      return false;
    },
    isRestrictLearner() {
      const lmsSettings = this.authUser?.dealer?.lms_settings;

      if (!lmsSettings?.length || useAuthStore().isSuperAdmin || useAuthStore().isAccountManager) {
        return true;
      }

      if (useAuthStore().isDealerManager) {
        return !lmsSettings.some(setting => setting.system_name === "restrict_learner" && setting.role_name === 'specific-dealer-manager');
      }

      if (useAuthStore().isLearner) {
        return !lmsSettings.some(setting => setting.system_name === "restrict_learner" && setting.role_name === 'salesperson');
      }

      return true;
    }
  },
  actions: {
    async fetchLmsSettings() {
      try {
        this.loadingSettings = true;
        const response = await http().get("/v3/lms-settings/list");
        this.settings = response.data;
      } catch (error) {
        useHandleErrorStatus(error);
      } finally {
        this.loadingSettings = false;
      }
    },
    async checkSettingIfAction(settingId, role = null) {
      let response = null;
      try {
        const res = await http().get(`/v3/lms-settings/check/${settingId}`, { params: { role } });
        response = res.data;
      } catch (error) {
        useHandleErrorStatus(error);
      }

      return response;
    },

    async storeSetting(settingId, rolePayload = null) {
      try {
        await http().post(`/v3/lms-settings/store/${settingId}`, { 'role' : rolePayload });
      } catch (error) {
        useHandleErrorStatus(error);
      }
    },
    async handleTurnOffSetting(settingId, rolePayload = null) {
      try {
        await http().post(`/v3/lms-settings/remove/${settingId}`, { 'role': rolePayload });
      } catch (error) {
        useHandleErrorStatus(error);
      }
    },
  },
});
