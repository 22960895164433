<!-- eslint-disable vue/no-dupe-v-else-if -->
<template>
  <Disclosure
    as="nav"
    :class="[
      'bg-white',
      'shadow',
      'sticky',
      'top-0',
      dialogStore.isDialogVisible ? zIndexValue : 'z-[4000]',
    ]"
    v-slot="{ open }"
  >
    <div class="mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex">
          <div class="-ml-2 mr-2 flex items-center lg:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton
              class="inline-flex items-center justify-center p-2 rounded-md text-untitled-gray-400 hover:text-untitled-gray-500 hover:bg-untitled-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-untitled-gray-500"
            >
              <span class="sr-only">Open main menu</span>
              <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
          <div class="flex-shrink-0 flex items-center overflow-hidden">
            <router-link
              :to="{ name: 'home' }"
              style="background: linear-gradient(90deg, #c0ccd8, 1.23%, #eaecf0, 100%)"
            >
              <img
                class="block lg:hidden h-8 w-auto"
                :src="
                  company_logo ||
                  'https://lms-v3.s3.amazonaws.com/default_logo_hudson.png'
                "
                onerror="this.src='https://lms-v3.s3.amazonaws.com/default_logo_hudson.png';"
                alt="Company Logo"
              />
              <img
                class="hidden lg:block h-auto w-40"
                :src="
                  company_logo ||
                  'https://lms-v3.s3.amazonaws.com/default_logo_hudson.png'
                "
                onerror="this.src='https://lms-v3.s3.amazonaws.com/default_logo_hudson.png';"
                alt="Company Logo"
              />
            </router-link>
          </div>
          <div
            class="hidden md:flex md:space-x-4 cursor-pointer"
            v-for="item in navigationMenus"
            :key="item.name"
          >
            <a
              v-if="
                item.name === 'Home' ||
                item.name === 'Active Role Plays' ||
                item.name === 'My Comments' ||
                item.name === 'News Feed'
              "
              @click="changePage(item.href)"
              :class="[
                currentRouteName == item.route
                  ? 'text-untitled-gray-900 border-untitled-gray-500'
                  : 'text-untitled-gray-500 border-transparent hover:border-untitled-gray-300 hover:text-untitled-gray-700',
              ]" class="flex justify-center items-center px-4 pt-1 border-b-2 text-xs sm:text-sm font-medium">
              {{ item.name }}
            </a>
            <ReportMenus v-else-if="item.name === 'Reports'" />
            <AdminPortalMenus class="mr-3" v-has-restrict-learner v-else-if="item.name === 'Admin Portal'" />
            <ResourceLinkMenus v-else-if="item.name === 'Resource Links'" />
            <ResourceManager v-else-if="item.name === 'Resource Manager'" />
            <a v-else @click="redirect(item.href)" :class="[
              currentRouteName == item.route
                ? 'text-untitled-gray-900 border-untitled-gray-500'
                : 'text-untitled-gray-500 border-transparent hover:border-untitled-gray-300 hover:text-untitled-gray-700',
            ]" class="inline-flex items-center px-4 pt-1 border-b-2 text-sm font-medium">
              {{ item.name }}
            </a>
          </div>
        </div>
        <div class="flex items-center">
          <!--          <div-->
          <!--            v-if="isSuperAdmin || isA || isDealerManager"-->
          <!--            class="hidden flex-shrink-0 xl:block lg:block md:block"-->
          <!--          >-->
          <!--            <button-->
          <!--              @click="openInviteUsersDialog(true)"-->
          <!--              type="button"-->
          <!--              class="w-full xl:w-auto lg:w-auto relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium xl:rounded-md lg:rounded-md text-untitled-gray-600 bg-untitled-gray-200 shadow-sm hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500 mr-3"-->
          <!--            >-->
          <!--              <UserAddIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />-->
          <!--              <span>Invite Users</span>-->
          <!--            </button>-->
          <!--          </div>-->

          <div class="hidden flex-shrink-0 xl:block lg:block md:block">
            <button
              @click="setCreateUnitDialog(true)"
              type="button"
              class="w-full xl:w-auto lg:w-auto relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium xl:rounded-md lg:rounded-md text-untitled-gray-600 bg-untitled-gray-200 shadow-sm hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
              v-has-restrict-learner
            >
              <PlusSmIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              <span>Upload Content</span>
            </button>
            <create-unit-dialog
              :isCreateUnitDialogOpen="isCreateUnitDialogOpen"
              @closeCreateUnitDialog="setCreateUnitDialog(false)"
            />
          </div>
          <div class="ml-2 flex-shrink-0">
            <global-search-dialog />
          </div>
          <div class="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
            <TopNotification />
            <TopAppsMenu :apps="apps" />
            <!-- Profile dropdown -->
            <Menu as="div" class="ml-3 relative">
              <div>
                <MenuButton
                  class="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
                >
                  <div
                    class="inline-flex overflow-hidden relative justify-center items-center w-10 h-10 bg-gray-100 rounded-full dark:bg-gray-600"
                  >
                    <span
                      class="font-medium text-gray-600 dark:text-gray-300"
                      v-if="!profilePicture"
                      >{{ initials }}</span
                    >
                    <img class="w-full" v-else :src="profilePicture" :alt="initials" />
                  </div>
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <MenuItem v-slot="{ active }">
                    <router-link
                      :to="{ name: 'mynotes' }"
                      :class="[
                        active ? 'bg-untitled-gray-100' : '',
                        'block px-4 py-2 text-sm text-untitled-gray-700',
                      ]"
                    >
                      <AnnotationIcon class="mr-2 h-5 w-5 inline" />My comments
                    </router-link>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a
                      @click="redirect('/central/profile')"
                      :class="[
                        active ? 'bg-untitled-gray-100' : '',
                        'block px-4 py-2 text-sm text-untitled-gray-700  cursor-pointer',
                      ]"
                    >
                      <UserIcon class="mr-2 h-5 w-5 inline" />Profile
                    </a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a
                      @click="redirect('https://docs.revdojo.com/')"
                      :class="[
                        active ? 'bg-untitled-gray-100' : '',
                        'block px-4 py-2 text-sm text-untitled-gray-700  cursor-pointer',
                      ]"
                    >
                      <QuestionMarkCircleIcon class="mr-2 h-5 w-5 inline" />FAQ
                    </a>
                  </MenuItem>
                  <MenuItem v-if="isSuperAdmin" v-slot="{ active }">
                    <a
                      @click="redirect('/central/preference')"
                      :class="[
                        active ? 'bg-untitled-gray-100' : '',
                        'block px-4 py-2 text-sm text-untitled-gray-700  cursor-pointer',
                      ]"
                    >
                      <CogIcon class="mr-2 h-5 w-5 inline" />Preference
                    </a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }"
                    v-if="isSuperAdmin || isAccountManager"
                  >
                    <router-link
                      :to="{ name: 'LMS Settings Page' }"
                      :class="[
                        active ? 'bg-untitled-gray-100' : '',
                        'block px-4 py-2 text-sm text-untitled-gray-700',
                      ]"
                    >
                      <AdjustmentsIcon class="mr-2 h-5 w-5 inline" />Settings
                    </router-link>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <search-user-dialog v-model="switchDialogOpen" :active="active" />
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <button
                      @click="logout()"
                      type="button"
                      :class="[
                        active ? 'bg-untitled-gray-100' : '',
                        'flex px-4 py-2 text-sm text-untitled-gray-700 w-full justify-start items-start',
                      ]"
                    >
                      <LogoutIcon class="mr-2 h-5 w-5 inline" />Sign out
                    </button>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>
    </div>

    <DisclosurePanel class="lg:hidden">
      <div class="pt-2 pb-3 space-y-1" v-for="item in navigationMenus" :key="item.name">
        <DisclosureButton
          v-if="
            item.name === 'Home' ||
            item.name === 'Reports' ||
            item.name === 'Active Role Plays' ||
            item.name === 'Leaderboard' ||
            item.name === 'My Notes' ||
            item.name === 'News Feed'
          "
          as="a"
          @click="changePage(item.href)"
          :class="[
            currentRouteName == item.route
              ? 'bg-untitled-gray-100 border-untitled-gray-500 text-untitled-gray-700'
              : 'border-transparent text-untitled-gray-500 hover:bg-untitled-gray-100 hover:border-untitled-gray-300 hover:text-untitled-gray-700',
          ]"
          class="block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
          >{{ item.name }}
        </DisclosureButton>
        <DisclosureButton
          v-else
          as="a"
          @click="redirect(item.href)"
          :class="[
            currentRouteName == item.route
              ? 'bg-untitled-gray-100 border-untitled-gray-500 text-untitled-gray-700'
              : 'border-transparent text-untitled-gray-500 hover:bg-untitled-gray-100 hover:border-untitled-gray-300 hover:text-untitled-gray-700',
          ]"
          class="block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
          >{{ item.name }}
        </DisclosureButton>
      </div>
      <div>
        <button
          @click="setCreateUnitDialog"
          type="button"
          class="w-full xl:w-auto lg:w-auto relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium xl:rounded-md lg:rounded-md text-untitled-gray-600 bg-untitled-gray-200 shadow-sm hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
        >
          <PlusSmIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          <span>Upload Content</span>
        </button>
      </div>
      <div class="pt-4 pb-3 border-t border-untitled-gray-200">
        <div class="flex items-center px-4 sm:px-6">
          <div class="flex-shrink-0">
            <div
              class="inline-flex overflow-hidden relative justify-center items-center w-10 h-10 bg-gray-100 rounded-full dark:bg-gray-600"
            >
              <span class="font-medium text-gray-600 dark:text-gray-300">{{
                initials
              }}</span>
            </div>
          </div>
          <div class="ml-3">
            <div class="text-base font-medium text-untitled-gray-800">
              {{ user.name }}
            </div>
            <div class="text-sm font-medium text-untitled-gray-500">
              {{ user.email }}
            </div>
          </div>
          <button
            type="button"
            class="hidden ml-auto flex-shrink-0 bg-white p-1 rounded-full text-untitled-gray-400 hover:text-untitled-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
          >
            <span class="sr-only">View notifications</span>
            <BellIcon class="h-6 w-6" aria-hidden="true" />
          </button>
          <button
            type="button"
            class="ml-auto flex-shrink-0 bg-white p-1 rounded-full text-untitled-gray-400 hover:text-untitled-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
          >
            <span class="sr-only">View App test</span>
            <ViewGridIcon
              @click="viewApps = !viewApps"
              class="h-6 w-6"
              aria-hidden="true"
            />
          </button>
        </div>
        <div v-if="viewApps" class="flex flex-col">
          <a
            v-for="client in apps"
            :key="client.link"
            :href="client.link"
            target="_blank"
            class="border-transparent text-untitled-gray-500 hover:bg-untitled-gray-100 hover:border-untitled-gray-300 hover:text-untitled-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
          >
            <img class="w-5 inline-block" :src="client.cover_photo" alt="App Menu" />
            {{ client.name }}
          </a>
        </div>
        <div class="mt-3 space-y-1  cursor-pointer">
          <DisclosureButton
            as="a"
            @click="redirect('/central/profile')"
            class="block px-4 py-2 text-base font-medium text-untitled-gray-500 hover:text-untitled-gray-800 hover:bg-untitled-gray-100 sm:px-6"
          >
            <UserIcon class="mr-2 h-5 w-5 inline" />Profile
          </DisclosureButton>
          <DisclosureButton
            as="a"
            @click="redirect('https://docs.revdojo.com/')"
            class="block px-4 py-2 text-base font-medium text-untitled-gray-500 hover:text-untitled-gray-800 hover:bg-untitled-gray-100 sm:px-6"
          >
            <QuestionMarkCircleIcon class="mr-2 h-5 w-5 inline" />FAQ
          </DisclosureButton>
          <DisclosureButton
            v-if="isSuperAdmin"
            as="a"
            @click="redirect('/central/preference')"
            class="block px-4 py-2 text-base font-medium text-untitled-gray-500 hover:text-untitled-gray-800 hover:bg-untitled-gray-100 sm:px-6"
          >
            <CogIcon class="mr-2 h-5 w-5 inline" />Preference
          </DisclosureButton>
          <DisclosureButton as="a" @click="openWeeklyReportSettings"
          v-if="isSuperAdmin || isAccountManager"
          class="block px-4 py-2 text-base font-medium text-untitled-gray-500 hover:text-untitled-gray-800 hover:bg-untitled-gray-100 sm:px-6">
            <CogIcon class="mr-2 h-5 w-5 inline" />Weekly Report
            Settings
          </DisclosureButton>
          <DiscloseButton v-if="isAbleToSwitch">
            <div class="ml-[10px]">
              <search-user-dialog v-model="switchDialogOpen" />
            </div>
          </DiscloseButton>
          <button
            type="button"
            @click="logout()"
            class="block px-4 py-2 text-base font-medium text-untitled-gray-500 hover:text-untitled-gray-800 hover:bg-untitled-gray-100 sm:px-6"
          >
            <LogoutIcon class="mr-2 h-5 w-5 inline" />Sign out
          </button>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>

  <!-- <weekly-report-settings v-if="isWeeklyReportOpen && isDialogVisible" /> -->

  <InviteUsersDialog
    :open="showInviteUsersDialog"
    @cancelEvent="showInviteUsersDialog = false"
    @sendEvent="handleSendEvent"
    :error="errorData"
    v-if="showInviteUsersDialog"
  />

  <switch-user-dialog v-model="switchDialogOpen" v-if="switchDialogOpen" />
  <NotificationDialog
    v-if="showNotificationDialog"
    @close="showNotificationDialog = false"
    :content="message"
  />
</template>
<script setup>
import { ref, inject, onMounted, computed } from "vue";
// import WeeklyReportSettings from "./WeeklyReportSettings.vue";
import InviteUsersDialog from "@/components/dialogs/InviteUsersDialog";
import { useWeeklyReportStore } from "@/stores/useWeeklyReportSettings";

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import {
  BellIcon,
  MenuIcon,
  XIcon,
  ViewGridIcon,
  UserIcon,
  CogIcon,
  AdjustmentsIcon,
  LogoutIcon,
  PlusSmIcon,
  QuestionMarkCircleIcon,
  AnnotationIcon,
} from "@heroicons/vue/outline";
import TopNotification from "./header/TopNotification.vue";
import TopAppsMenu from "./header/TopAppsMenu.vue";
import GlobalSearchDialog from "./GlobalSearchDialog";
import SearchUserDialog from "./SearchUserDialog";
import SwitchUserDialog from "./SwitchUserDialog";
import { useAuthStore } from "@/stores";
import Bugsnag from "@bugsnag/js";
import ResourceLinkMenus from "./header/ResourceLinkMenus.vue";
import ResourceManager from "./header/ResourceManager.vue";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import NotificationDialog from "@/components/partials/NotificationDialog.vue";
import { useDialogStore } from "@/stores/useDialogStore";

const weeklyReportStore = useWeeklyReportStore();

// const { isDialogVisible } = storeToRefs(weeklyReportStore);

const dialogStore = useDialogStore();
const authStore = useAuthStore();
const auth = useAuth();

const switchDialogOpen = ref(false);

const isSuperAdmin = computed(() => authStore.isSuperAdmin);
const isAccountManager =computed(() => authStore.isAccountManager);
const isDealerManager =computed(() => authStore.isDealerManager);
const isMultiStoreManager =computed(() => authStore.isMultiStoreManager);

const isCreateUnitDialogOpen = ref(false);
const showInviteUsersDialog = ref(false);

const setCreateUnitDialog = (isOpen) => {
  isCreateUnitDialogOpen.value = isOpen;
};

const openWeeklyReportSettings = () => {
  weeklyReportStore.showDialog();
};

// const openInviteUsersDialog = (isOpen) => {
//   showInviteUsersDialog.value = isOpen;
// };

const profilePicture = computed(() => authStore.user.profile_picture);

const zIndexValue = computed(() => {
  const value = dialogStore.zIndexValue;

  return `z-${value}`;
});

// const isWeeklyReportOpen = computed(() => weeklyReportStore.isDialogVisible);

let company_logo = "https://lms-v3.s3.amazonaws.com/default_logo_hudson.png";
try {
  company_logo = authStore.$state.user.dealer.company_layout.company_logo;
} catch (e) {
  Bugsnag.notify(e);
}

const navigation = {
  main: [
    { name: "Home", href: "/", current: true, route: "home" },
    {
      name: "Reports",
      href: "/reports",
      current: false,
      route: "reports",
    },
    {
      name: "Active Role Plays",
      href: "/active-role-plays",
      current: false,
      route: "active-role-plays",
    },
    {
      name: "Resource Manager",
      href: "/resource-manager",
      current: false,
      route: "filemanager",
    },
    {
      name: "News Feed",
      href: "/news-feed",
      current: false,
      route: "Announcement Home Page",
    },
  ],
};

const apps = ref([]);

const viewApps = ref(false);

const axios = inject("axios");
onMounted(async () => {
  await axios.get("/api/central/get-app-menu").then(({ data }) => {
    apps.value = data;
  });
});

const navigationMenus = computed(() => {
  let menus = [...navigation.main];

  if (isSuperAdmin.value || isAccountManager.value || isDealerManager.value || isMultiStoreManager.value) {
    menus = [
      ...menus,
      {
        name: "Admin Portal",
        href: "/central/manage-users",
        current: false,
        route: "#",
      },
    ];
  }

  menus = [
    ...menus,
    {
      name: "Resource Links",
      href: "/",
      current: false,
      route: "#",
    },
  ];

  return menus;
});

const showNotificationDialog = ref(false);
const message = ref("");

const handleSendEvent = () => {
  showNotificationDialog.value = true;
  message.value = "Invitation Sent";
  showInviteUsersDialog.value = false;
};

function logout() {
  axios.get("/api/okta/logout").then(({ data }) => {
    auth.logout();
    localStorage.removeItem("nextUrl");
    window.location.href = data;
  });
}
</script>
<script>
import { createPopper } from "@popperjs/core";
import CreateUnitDialog from "../unit/CreateUnitDialog";
import AdminPortalMenus from "./header/AdminPortalMenus.vue";
import ReportMenus from "./header/ReportMenus.vue";
// import { storeToRefs } from "pinia";
export default {
  name: "GlobalHeader",
  components: { CreateUnitDialog },
  data() {
    return {
      tooltipShow: false,
      loading: false,
      accessClients: [],
    };
  },
  computed: {
    is_manager: {
      get() {
        return this.$auth.$vm.state.data.is_manager;
      },
    },
    currentRouteName() {
      return this.$route.name;
    },
    user() {
      return this.$auth.user();
    },
    initials() {
      let initials = this.user.name.split(" ");

      if (initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0);
      } else {
        initials = this.user.name.substring(0, 2);
      }

      return initials.toUpperCase();
    },
  },

  mounted() {
    this.renderSiteApp();
  },
  methods: {
    homePage() {
      this.$router.push("/");
    },
    changePage(url) {
      this.$router.push(url);
    },
    toggleTooltip: function () {
      if (this.tooltipShow) {
        this.tooltipShow = false;
      } else {
        this.tooltipShow = true;
        createPopper(this.$refs.btnRef, this.$refs.tooltipRef, {
          placement: "bottom",
        });
      }
    },
    renderSiteApp() {
      this.loading = true;
      this.$http
        .get(`/api/central/access`)
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          Bugsnag.notify(e);
        });
    },
    redirect(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
